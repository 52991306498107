<template>
  <div class="pdf-container">
    <div class="mt-10" style="text-align: center;">
      <span class="page-heading"> FAQ &amp; Support</span>
      <br><br>
    </div>
    <vue-pdf-app theme="light" :pdf=pdfUrl :config="config" page-scale="page-width"></vue-pdf-app>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pdfUrl: null,
      title: null,
      config: {
        secondaryToolbar: {
          documentProperties: false,
        },
        toolbar: {
          toolbarViewerRight: {
            presentationMode: true,
            openFile: false,
            print: true,
            download: true,
            viewBookmark: false,
          },
        }
      },
    }
  },
  mounted() {
    this.$gtag.event("landed on FAQ & support page", {
      'event_category': "Info Page"
    });

    this.$feedback.showLoading();

    this.$cppClient.post('/info/pdf/fetch', {
      pdfId: process.env.VUE_APP_FAQ_AND_SUPPORT_PDF_ID,
    })
        .then(({data}) => {
          this.pdfUrl = data.pdf;
          this.title = data.title;
        })
        .catch(() => {
        })
        .finally(() => {
          this.$feedback.hideLoading();
        })
  }
}
</script>

<style lang="scss" scoped>
.pdf-container {
  height: 80vh;
  margin-top: 75px;
  max-width: 1185px;
  margin-left: auto;
  margin-right: auto;
  @media screen and (min-width: 1001px) {
    margin-top: 150px;
  }
}

#outerContainer {
  z-index: 0 !important;
}

.pdf-app.light {
  --pdf-toolbar-color: white;
  --pdf-toolbar-font-color: grey;
  --pdf-input-color: grey;
}

::v-deep .toolbar {
  z-index: 150 !important;
}
</style>
